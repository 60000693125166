/* style.css */

.specification {
    background-color: #f5f5f5;
    padding: 20px;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 5px;
    text-align: left;
    margin-top: 50px;
  }
  
  .specification h2 {
    color: #374f5a;
    font-size: 30px;
    margin-bottom: 10px;
    text-decoration: underline;
    text-align: center;
    font-family: "Cinzel";
    font-weight:bold;
    text-transform: uppercase;
  }
  
  .specification ul {
    list-style: none;
    padding: 0;
  }
  
  .specification li {
    margin: 10px 0;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  .specification strong {
    font-weight: bold;
    margin-left: 10px;
  }
  
  /* Style for rows of specifications */
  .spec-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Style for each pair of specifications */
  .spec-details {
    flex: 0 0 48%; /* Adjust the width as needed */
    padding: 10px;
    margin-right: 2%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Media query for responsive design */
  @media (max-width: 768px) {
    .spec-details {
      flex: 0 0 100%; /* Display as full width on smaller screens */
      margin-right: 0;
    }
  }
  