.location_container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* padding-left: 161px; */
    /* padding-right: 161px; */
    flex-direction: column;
  }
.map-layout {
    width: 600px;
    border: 1px solid #374f5a;
  }
  @media screen and (max-width: 768px) {
    .map-layout {
      width: 300px;
      border: 1px solid #374f5a;
    }}
  @media screen and (max-width: 768px) {
    .location_container .map-container {
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
    }
    }
    .image-container{
        margin-top: 20px;
        /* background-color: red; */
      }
      @media screen and (max-width:768px) {
         .image-container{
          /* background-color: yellow; */
        }  
      }
      .image-container .image-size{
         /* padding-left: 161px;
        padding-right: 161px;   */
      }
      @media screen and (max-width:768px) {
        .image-container .image-size{
          display:none;
        }
      }
.location-container{
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center;
  align-content: center; */
}
@media screen and (max-width:768px) {
  .location-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }}

.proximeties{
  text-align:left;
  width: 300px;
  margin-left: 40px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
@media screen and (max-width:768px) {
  .proximeties{
    text-align:left;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }}