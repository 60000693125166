
.amenities_container {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  /* padding-left: 161px; */
  /* padding-right: 161px; */
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .amenities_container {
    /* background-color: red; */
    /* margin-top: -50px; */
  }
}

@media screen and (max-width: 768px) {
.text-container {
  display: flex;
  justify-content: center;
}
}
.section-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  align-content: center;
  align-self: center;
  margin-top: 20px;
  animation: zoom-in 1s both;
}
@media screen and (max-width:768px) {
  .section-content{
    display: flex;
    flex-direction: column;
    margin-top: 20px;    
  }
}
.image-container{
  display: flex;
  margin-top: 20px;
  justify-content: center;
  /* background-color: red; */
}
@media screen and (max-width:768px) {
   .image-container{
    display: flex;
  margin-top: 20px;
  /* justify-content: center; */
    background-color: yellow;
  }  
}
.image-container .image-size{
   /* padding-left: 161px;
  padding-right: 161px;   */
}
@media screen and (max-width:768px) {
  .image-container .image-size{
    display:flex;
    background-color: red;
  }
}
@keyframes zoom-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}