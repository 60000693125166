/* header {
   display: flex;
}
body.fixed-header .navbar {
    width: 100%;
    z-index: 999;
    background: #374f5a!important;
    box-shadow: 0px -1px 11px 3px #000000c7;
}
body.fixed-header .navbar {
    width: 100%;
    z-index: 999;
}
.navbar.fixed {
 
    width: 100%;
    z-index: 999;
    background: #374f5a!important;
    box-shadow: 0px -1px 11px 3px #000000c7;
}
.navbar {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
}
@media (min-width: 768px){
.navbar {
    border-radius: 4px;
}}
.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}
@media (min-width: 1200px){
.container {
    width: 1170px;
}}
@media (min-width: 992px){
.container {
    width: 970px;
}}
@media (min-width: 768px){
.container {
    width: 750px;
}}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
div {
    display: block;
}
body {
    letter-spacing: 0.01rem;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
}
@media only screen and (min-width: 768px){
.visualcomposerstarter:not(.menu-sandwich) .navbar-wrapper {
    display: block;
}}
.navbar-wrapper {
    padding: 5px 15px;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) .navbar-header {
    max-width: 25%;
}}
.visualcomposerstarter .navbar-header {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}
@media (min-width: 768px){
.navbar-header {
    float: left;
}}
@media (min-width: 768px){
.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
    margin-left: -15px;
}}
.navbar-brand {
    height: auto;
    padding: 0 15px;
}
.navbar-brand {
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    height: 50px;
}
a {
    background-color: transparent;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) .navbar-header img {
    max-width: 100%;
    height: auto;
}}
.navbar-brand img {
    max-width: 100%;
    height: auto;
}
img {
    opacity: 1;
    -webkit-transition: opacity .5s ease;
    -o-transition: opacity .5s ease;
    transition: opacity .5s ease;
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) .navbar-toggle {
    display: none!important;
}}
.navbar-toggle {
    margin-bottom: 0;
}
@media (min-width: 768px){
.navbar-toggle {
    display: none;
}}
.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.navbar-toggle .icon-bar {
    width: 20px;
    border-radius: 0;
    background-color: #333;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu {
    text-align: right;
    position: static;
    width: 100%;
    background: 0 0;
    padding: 0;
    overflow-y: inherit;
    margin-left: 28%;
}}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu .button-close {
    display: none;
}}
#main-menu .button-close {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-bottom: 25px;
    color: #fff;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu>ul {
    display: inline-block;
    margin: 7.5px 0;
    text-align: left;
}}
#main-menu>ul {
    margin: 0;
    float: none;
}
@media (min-width: 768px){
.navbar-nav {
    float: left;
    margin: 0;
}}
.navbar-nav {
    margin: 7.5px -15px; 
}
.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}
body:not(.menu-sandwich) #main-menu>ul>li {
    line-height: 1em!important;
    color: #555!important;
    font-size: 15.4px!important;
    margin-top: 8px!important;
    font-weight: 400!important;
    cursor: grab!important;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu>ul>li {
    font-size: 18px;
}}
#main-menu>ul>li {
    font-size: 22px;
}
#main-menu ul li {
    position: relative;
}
@media (min-width: 768px){
.navbar-nav>li {
    float: left;
}}
.nav>li {
    position: relative;
    display: block;
}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu>ul>li>a:focus, body:not(.menu-sandwich) #main-menu>ul>li>a:hover {
    color: #333;
}}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu>ul>li>a {
    line-height: 1em;
    color: #555;
}}
@media only screen and (min-width: 768px){
body:not(.menu-sandwich) #main-menu>ul>li a {
    padding: 0.55em 0.83em;
    text-decoration: none;
}}
#main-menu>ul>li>a:focus, #main-menu>ul>li>a:hover {
    color: #ccc;
    background: 0 0;
}
#main-menu ul li a:focus, #main-menu ul li a:hover {
    text-decoration: none;
    color: #fff;
}
.header-widgetised-area .widget_text, #main-menu > ul > li > a, .entry-full-content .entry-author-data .author-name, .nav-links.post-navigation a .post-title, .comments-area .comment-list .comment-author, .comments-area .comment-list .reply a, .comments-area .comment-form-comment label, .comments-area .comment-form-author label, .comments-area .comment-form-email label, .comments-area .comment-form-url label, .comment-content blockquote, .entry-content blockquote {
    font-family: 'cinzel'!important;
    font-weight: 500;
    font-size: 14px!important;
    color: #ffffff!important;
    background: transparent!important;
}
.menu-item i._before, .rtl .menu-item i._after {
    margin-right: 0.25em;
}

.menu-item i._mi {
    width: auto;
    height: auto;
    margin-top: -0.265em;
    font-size: 1.2em;
    line-height: 1;
}
.menu-item i._mi, .menu-item img._mi {
    display: inline-block;
    vertical-align: middle;
}
.fa {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-home:before {
    content: "\f015";
} */

/* Default styles for desktop */

  

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #374f5a;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }
@media screen and (max-width: 768px) {
    .fixed-header {
        display: block;
       position: relative;
    }
  }
  .custom-toggler .navbar-toggler-icon {
    color: white !important;
  }
  

  